.dropdown {
  margin-bottom: 20px;
}
.buttonContainer {
  display: block;
  text-align: left;
}
form {
  display: flex;
  flex-direction: column;
}

label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}
#m-container {
  background: #b1bdba;
  padding: 10px 0;
  margin-bottom: 100px;
  border-radius: 6px;
  text-align: center;
}

select,
input,
textarea {
  margin-bottom: 10px;
  width: 98%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.fileupload {
  margin-bottom: 20px;
}

.submitButton
  /* .getId button,
  .formContainer button { */ {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  cursor: pointer;
  background: #058109;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 60%;
  margin: 0 auto;
}

.img-container img {
  /* width: 100%; */
  height: auto;
  margin-bottom: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.img-container button {
  width: 80%;
  padding: 10px;
  background-color: #41bfcf;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.img-container button:hover {
  background: rgb(12, 172, 164);
}
