.mBar {
  /* margin-top: 20px;
  width: 640px;
  height: 50px; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 15px;

  border: 1px solid #ccc;
  border-radius: 5px;
  /* margin-bottom: 10px; */
}

.close {
  cursor: pointer;
  color: #fa0505;
}