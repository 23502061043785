.dropdown {
  margin-bottom: 20px;
}
.buttonContainer {
  display: block;
  text-align: left;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 60%;
  align-items: left;
}
label {
  display: block;
  margin-bottom: 5px;
}
#m-container {
  background: #67837c;
  padding: 10px 0;
  margin-bottom: 100px;
  border-radius: 6px;
  text-align: center;
}

input,
textarea {
  width: 100% !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.fileupload {
  margin-bottom: 20px;
}

.submitButton
/* .getId button,
.formContainer button { */ {
  width: 100% !important;
  background-color: #39693b;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover
/* .getId button:hover,
.formContainer button:hover { */ {
  cursor: pointer;
  background: #698b6b;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 60%;
  margin: 0 auto;
  /* background: #9eaaac; */
}

.img-container img {
  /* width: 100%; */
  height: auto;
  margin-bottom: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.img-container button {
  width: 80%;
  padding: 10px;
  background-color: #41bfcf;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.img-container button:hover {
  background: rgb(12, 172, 164);
}

@media screen and (max-width: 1000px) {
  form div {
    width: 90%;
    margin-bottom: 10px;
  }
  .dropdown {
    margin-bottom: 10px;
  }
}
