/* %%%%%%%%%Edditin Modal%%%%%%%%%%%%%%% */
.customInput {
  background-color: #dbe0df;
}

.customCancelButton {
  background-color: #dc3545;
}

.customSaveButton {
  background-color: #28a745;
}
