:root {
  scroll-behavior: smooth;
  --primary: #4154f1;
  --second: #717ff5;
  --byColor: #f6f9ff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
  background: var(--byColor);
}
a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: var(--second);
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Numito", sans-serif;
}
