:root {
  --primary-bg: #fff;
  /* --primary-bg: #242526; */
  --secondary-bg: #fff;
  --primary-text-color: #555;
  --secondary-text-color: #cecece;
  --border-radius: 8px;
  --speed: 500ms;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
