/*sidebar style*/
.app {
  margin-top: 80px;
  width: 300px;
  min-height: calc(100%-100px);
  background: rgb(243, 243, 243);
  border-radius: 0.2rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
/*SidebarItem style*/
.sidebar-item {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.sidebar-item:hover {
  background-color: #adaaaa;
}

.item-label {
  display: flex;
  align-items: center;
}

.item-label span {
  margin-left: 10px;
}

.sub-items {
  margin-top: 10px;
  padding-left: 20px;
}

/* SidebarSubItem.css */
.sidebar-subitem {
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.sidebar-subitem:hover {
  background-color: #6e6a6a;
}
