.linkPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-link {
  margin: 5px 0;
}

.button {
  width: 500px;
  height: 50px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 22px;
  border: none;

  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}
