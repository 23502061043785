.tableHeadRow th {
  background: rgb(29, 105, 22);
  color: white;
}
.itemsContainer {
  margin: 5px 40px;
}
.itemsTable {
  width: 100%;
}
.itemImage {
  width: 200px;
  height: auto;
}
.itemDocument {
  margin-top: 30px;
}
.itemDocument td {
  background: #e5ebe7;
}

.itemsTableBody tr {
  margin-top: 40px;
}
.iconsAction {
  font-size: 24px;
  margin-right: 10px;
  /* color: rgb(49, 215, 245); */
}
.iconsAction:hover {
  cursor: pointer;
}
.iconsAction:last-child:hover {
  color: rgb(179, 33, 33);
}

/* %%%%%%%%UserAccounts%%%%%%%% */
.usersCreate {
  display: flex;
  justify-content: space-between;
}
.createAccount {
  height: 30px;
  width: 150px;
  background: #50a762;
  color: white;
  margin-top: 20;
}

/* %%%%%%%%%%%%Add new user%%%%%%%%%%%%%%%% */
#createAccountForm input {
  background: #edf5ee;
  border-radius: 10px;
  height: 40px;
}

/* %%%%%%%%%%%Reset Password%%%%%%%%%%%%%5 */

.resetPasswordInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}
