@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer components {
  .link {
    @apply cursor-pointer hover:underline;
  }
  .button-outline {
    @apply rounded-md border-2 border-primary hover:bg-primary/80 hover:text-black duration-500 py-2 px-6 text-primary tracking-wider;
  }

  .primary-btn {
    @apply bg-primary text-white hover:bg-primary/80  duration-500 rounded-lg py-2 px-4;
  }
}
